import React, { useEffect, useState } from 'react';
import CountdownTimer from "./CountdownTime";
const Welcome = () => {
    const [username, setUsername] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [expiresAt, setExpiresAt] = useState(0);

    useEffect(() => {
        if (isLoggedIn) return;

        fetch('/webauthn/secure_endpoint', {
            method: 'GET',
            credentials: 'include'
        })
        .then(response => {
            if(response.ok) {
                return response.json();
            }
            throw new Error('Not authenticated');
        })
        .then(data => {
            setUsername(data.username);
            setExpiresAt(data.exp);
            setIsLoggedIn(true)
        })
        .catch(error => {
            console.error('Error fetching user data:', error);
        });
    }, [isLoggedIn]);

    return (
        <div>
            {isLoggedIn ? (<>
                <h1>Welcome back, {username}!</h1>
                <CountdownTimer expiresAt={expiresAt} setIsLoggedIn={setIsLoggedIn}/>
            </>) : (
                <h1>Please log in.</h1>
            )}
        </div>
    );
};

export default Welcome;
