import './App.css';
import USBComponent from "./usbComponent";
import Welcome  from "./Welcome";



import WebAuthnComponent from "./WebAuthn";
function App() {
  return (
    <div className="App">
        {/*<USBComponent />*/}
        <Welcome />
      <WebAuthnComponent />
    </div>
  );
}

export default App;
