import { useState, useEffect } from 'react';

const CountdownTimer = ({ expiresAt,setIsLoggedIn }) => {
    const [secondsLeft, setSecondsLeft] = useState(() => {
        // console.log(expiresAt - Date.now() / 1000)
        return Math.floor((expiresAt - Date.now()) / 1000);
    });

    useEffect(() => {
        // console.log( Math.floor((expiresAt -Date.now())/1000))
        if (secondsLeft <= 0) {
            setIsLoggedIn(false);
            return;
        }

        const timerId = setInterval(() => {
            setSecondsLeft(prevSeconds => {
                if (prevSeconds <= 1) {
                    clearInterval(timerId);
                    setIsLoggedIn(false);
                    return 0;
                }
                return prevSeconds - 1;
            });
        }, 1000);

        // console.log(secondsLeft)

        return () => clearInterval(timerId);
    }, [secondsLeft]);

    return <div>Session expires in: {secondsLeft} seconds</div>;
};

export default CountdownTimer;
