// import React from 'react';
import React, {useState} from 'react';

function base64urlToArrayBuffer(base64url) {
    let base64 = base64url.replace(/-/g, '+').replace(/_/g, '/');
    let raw = atob(base64);
    let rawLength = raw.length;
    let buffer = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; i++) {
        buffer[i] = raw.charCodeAt(i);
    }
    return buffer.buffer;
}

function arrayBufferToBase64url(arrayBuffer) {
    let uint8Array = new Uint8Array(arrayBuffer);
    let raw = String.fromCharCode(...uint8Array);
    let base64 = btoa(raw);
    return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}


async function startRegistration() {
    try {
        // Request the registration options from your FastAPI server
        const response = await fetch('/webauthn/begin_registration', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({username: 'USER_SOMETHING'}) // Replace 'your_username' with actual value
        });

        const data = await response.json();
        console.log(data)

        // Convert challenge and user.id from Base64URL to ArrayBuffer
        data.challenge = base64urlToArrayBuffer(data.challenge);
        data.user.id = base64urlToArrayBuffer(data.user.id);

        // Request the credential from WebAuthn API
        const credential = await navigator.credentials.create({publicKey: data});

        // Send the credential to your server for verification
        await finishRegistration(credential, data.user.name);
    } catch (error) {
        console.error('Registration failed:', error);
    }
}

async function finishRegistration(credential, username) {
    try {
        // Convert the credential to be suitable for server verification
        const publicKeyCredential = {
            id: credential.id,
            rawId: arrayBufferToBase64url(credential.rawId),
            type: credential.type,
            username: username,
            response: {
                clientDataJSON: arrayBufferToBase64url(credential.response.clientDataJSON),
                attestationObject: arrayBufferToBase64url(credential.response.attestationObject)
            }
        };

        const response = await fetch("/webauthn/finish_registration", {
            method: "POST",
            credentials: "include",  // Crucial for cookies to be sent and received cross-origin
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(publicKeyCredential)
        });


        const data = await response.json();

        if (data.status === 'OK') {
            // Handle successful registration
            console.log('registration successful');
        } else {
            // Handle registration errors
        }
    } catch (error) {
        console.error('Error during the registration process:', error);
    }
}

async function startAuthentication() {
    try {

        // Request the authentication options from your server
        const response = await fetch('/webauthn/begin_authentication', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({username: 'USER_SOMETHING'}) // Replace 'your_username' with actual value
        });

        const data = await response.json();
        // Convert challenge and allowCredentials.id from Base64URL to ArrayBuffer
        data.challenge = base64urlToArrayBuffer(data.challenge);
        for (let cred of data.allowCredentials) {
            cred.id = base64urlToArrayBuffer(cred.id);
        }

        // Request the assertion from WebAuthn API
        const assertion = await navigator.credentials.get({publicKey: data});

        // Send the assertion to your server for verification
        await finishAuthentication(assertion);
    } catch (error) {
        console.error('Authentication failed:', error);
    }
}

async function finishAuthentication(assertion) {
    try {
        // Convert the assertion to be suitable for server verification
        const publicKeyCredential = {
            id: assertion.id,
            rawId: arrayBufferToBase64url(assertion.rawId),
            type: assertion.type,
            response: {
                clientDataJSON: arrayBufferToBase64url(assertion.response.clientDataJSON),
                authenticatorData: arrayBufferToBase64url(assertion.response.authenticatorData),
                signature: arrayBufferToBase64url(assertion.response.signature),
                userHandle: arrayBufferToBase64url(assertion.response.userHandle)
            }
        };

        // Send the publicKeyCredential to your server for verification
        const response = await fetch('/webauthn/finish_authentication', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(publicKeyCredential)
        });

        const data = await response.json();

        if (data.status === 'OK') {
            // Handle successful authentication
        } else {
            // Handle authentication errors
        }
    } catch (error) {
        console.error('Error during the authentication process:', error);
    }
}


function WebAuthnComponent() {
    const [message, setMessage] = useState("");

    const handleRegistration = async () => {
        try {
            await startRegistration();
            setMessage("Registration successful!");
        } catch (error) {
            console.error('Registration failed:', error);
            setMessage(`Registration failed: ${error.message}`);
        }
    }

    const handleAuthentication = async () => {
        try {
            await startAuthentication();
            setMessage("Authentication successful!");
        } catch (error) {
            console.error('Authentication failed:', error);
            setMessage(`Authentication failed: ${error.message}`);
        }
    }

    return (
        <div>
            <h1>WebAuthn with FastAPI and YubiKey</h1>
            <button onClick={handleRegistration}>Register with YubiKey</button>
            <button onClick={handleAuthentication}>Authenticate with YubiKey</button>
            <p>{message}</p> // Display feedback message here
        </div>
    );
}

export default WebAuthnComponent;
